<template>
    <section class="ecommerce-application">
        <b-row>
            <b-col cols="12" md="6">
                <b-row>
                    <b-form @submit.prevent="_generateTemplate" class="w-100">
                        <b-col cols="12" v-for="(field, fk) in fields" :key="`field_${fk}`">
                            <b-form-group class="" :label="$t(`${field.label}`)">
                                <template v-if="field.type == 'text'">
                                    <b-input :required="true" :name="field.name" :disabled="loading" v-model="form[field.name]" :placeholder="field.placeholder" />
                                </template>
                                <template v-else-if="field.type == 'textarea'">
                                    <b-textarea
                                        :name="field.name" 
                                        :disabled="loading" 
                                        v-model="form[field.name]" 
                                        :placeholder="field.placeholder"
                                        :required="true"
                                    />
                                </template>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-button class="mb-2" type="submit" :variant="loading ? 'secondary' : 'primary'" :disabled="loading">
                                <template v-if="loading">
                                    Generating...
                                </template>
                                <template v-else>
                                    Generate
                                </template>
                            </b-button>
                        </b-col>
                    </b-form>
                </b-row>
            </b-col>
            <b-col v-if="generated" cols="12" md="6" class="mb-5">
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-end mb-1 mt-2">
                        <b-button size="sm" title="Save" variant="outline-secondary" class="mr-1" @click="_saveTemplete">
                            <img width="24" :src="require('@/assets/images/app-icons/save-48.png')" />
                        </b-button>
                        <b-button size="sm" title="Download" variant="outline-secondary" class="mr-1" @click="_downloadTemplate">
                            <img width="24" :src="require('@/assets/images/app-icons/icons8-download-48.png')" />
                        </b-button>
                        <b-button size="sm" title="Copy" variant="outline-secondary" @click="_copy">
                            <img width="24" :src="require('@/assets/images/app-icons/icons8-copy-48.png')" />
                        </b-button>
                    </b-col>
                    <b-col cols="12">
                        <quill-editor
                            id="blog-content"
                            v-model="templateText"
                            :options="snowOption"
                        />
                        <b-textarea id="copyInput" :value="templateText" style="height: 1px;width:1px;border: none; background: transparent;color: transparent;overflow: hidden;resize: none;padding: 0 0 0 0;" readonly />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </section>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'
import { showErrorNotification, showLoader, showSuccessNotification, hideLoader, showDangerNotification } from '@/@core/comp-functions/ui/app'
import { quillEditor } from 'vue-quill-editor'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

export default {
    name: 'GenerateAiTemplate',
    components: {
        quillEditor
    },
    props: {

    },

    data: () => ({
        loading: false,
        form: {},
        generatedId: null,
        templateText: null,
        savedTemplate: {},
        snowOption: {
            theme: 'snow',
        },
    }),

    computed: {
        ...mapGetters('aiTemplate', [
            'aiTemplatesLoaded',
            'aiTemplateBySlug',
            'aiGeneratedTemplateById',
        ]),
        template: {
            get(){
                return this.aiTemplateBySlug(this.$route.params.slug)
            }
        },
        fields: {
            get(){
                if(isEmpty(this.template)) return []
                const { fields } = this.template
                return eval(fields)
            }
        },
        generatedTemplate: {
            get(){
                if(isEmpty(this.generatedId)) return null;
                const generatedTemplate = this.aiGeneratedTemplateById(this.generatedId)
                return generatedTemplate
            }
        },
        generated: {
            get(){
                return !isEmpty(this.generatedTemplate)
            }
        }
    },
    mounted() {
        if(!this.aiTemplatesLoaded){
            showLoader()
            this.getAiTemplateGroups().then(r => {
                hideLoader()
            }).catch(e => {
                hideLoader()
            })
        }
    },
    methods: {
        isEmpty,
        ...mapActions('aiTemplate', [
            'getAiTemplateGroups',
            'generateTemplate',
            'saveTemplate',
        ]),
        _generateTemplate(){
            this.loading = true
            showLoader()
            const payLoad = {
                ...this.form,
                lang: 'English',
                tone: 'Professional',
                template_id: this.template.id
            }
            this.generateTemplate(payLoad).then(r => {
                if(typeof r === 'string'){
                    showDangerNotification(this, r)
                }else{
                    if (r._statusCode === 200) {
                        const {id, template} = r.responseData
                        this.generatedId = id
                        this.templateText = template
                    }else if (r._statusCode === 201) {
                        showErrorNotification(this, r.message)
                    }else {
                        showErrorNotification(this, "Something went wrong!")
                    }
                }
                this.loading = false
                hideLoader()
            }).catch((error) => {
                showErrorNotification(this, "Something went wrong!")
                this.loading = false
                hideLoader()
            });
        },
        _saveTemplete(){
            this.loading = true
            showLoader()
            const payLoad = {
                text: this.templateText,
                id: this.generatedId,
            }
            this.saveTemplate(payLoad).then(r => {
                if (r._statusCode === 200) {
                    const {saved_template} = r.responseData
                    this.templateText = saved_template
                    showSuccessNotification(this, "Template saved successfully!")
                }else if (r._statusCode === 201) {
                    showErrorNotification(this, r.message)
                }else {
                    showErrorNotification(this, r.message || "Something went wrong!")
                }
                this.loading = false
                hideLoader()
            }).catch((error) => {
                showErrorNotification(this, e.message || "Something went wrong!")
                this.loading = false
                hideLoader()
            });
        },
        _downloadTemplate(){
            this.loading = true
            showLoader()
            const payLoad = {
                text: this.templateText,
                id: this.generatedId,
            }
            this.saveTemplate(payLoad).then(r => {
                if (r._statusCode === 200) {
                    const {pdf_url} = r.responseData
                    if(!isEmpty(pdf_url)){
                        window.location.href = jwtDefaultConfig.baseEndpoint+pdf_url;
                    }
                }
                this.loading = false
                hideLoader()
            }).catch((error) => {
                this.loading = false
                hideLoader()
            });
        },
        _copy(){
            let copyInput = document.querySelector('#copyInput');
            copyInput.select();
            try {
                if(document.execCommand('copy')){
                    showSuccessNotification(this, 'Copied to clipboard');
                }else{
                    showErrorNotification(this, "Failed to copy");
                }
            } catch (err) {
                showErrorNotification(this, "Failed to copy");
            }
            window.getSelection().removeAllRanges()
        },
    },
    watch: {
        fields: {
            handler(nv){
                if(!isEmpty(nv)){
                    nv.forEach(f => {
                        this.form[f.name] = null
                    })
                }
            }
        }
    }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>